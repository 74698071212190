import Head from 'next/head'

import Footer from './Footer'
import Header from './Header'


export const siteTitle = 'Kaleidoscope Agency | Model, People, Actor, Face, Atypical Profil | Brussels Based | '
export const title = 'Kaleidoscope Agency'

export default function Layout({ children, home, isFlex, comingSoon }) {
    const containerStyle = {
        display: isFlex ? "flex" : "block",
        width: "100%",
        justifyContent: "space-between",
        flexFlow: "wrap",
    }

    return (
        <div>
            <Head>
                <link rel="icon" href="/favicon.ico" />
                <meta
                    name="description"
                    content="Learn how to build a personal website using Next.js"
                />
                <meta
                    property="og:image"
                    content={`https://og-image.vercel.app/${encodeURI(
                        siteTitle
                    )}.png?theme=light&md=0&fontSize=75px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg`}
                />
                <meta name="og:title" content={siteTitle} />
                <meta name="twitter:card" content="summary_large_image" />
            </Head>
            {!comingSoon && (
                <Header />
            )}
            <main style={containerStyle}>{children}</main>

            {home && (
                <Footer />
            )}
        </div>
    )
}