/** @jsxImportSource @emotion/react */
import React from 'react'
import Link from 'next/link'
import MenuButton from '../MenuButton'
import Menu from '../Menu'
import MenuItem from '../MenuItem'

import { logoContainerStyle, navLogoStyle } from './styles'

export default class App extends React.Component {
    constructor(props){
        super(props);
        this.state={
          menuOpen:false,
        }
      }

      handleMenuClick() {
        this.setState({menuOpen:!this.state.menuOpen});
      }

      handleLinkClick() {
        this.setState({menuOpen: false});
      }

      render(){
        const menu = [
            {
                name: 'Women',
                href: '/women'
            },
            {
                name: 'Men',
                href: '/men'
            },
            {
                name: 'Registration',
                href: '/register'
            },
            {
                name: 'Contact',
                href: '/contact'
            }
        ]
        const menuItems = menu.map((val,index)=>{
        return (
            <MenuItem 
            key={index} 
            href={val.href}
            delay={this.state.menuOpen ? `${(index * 0.1) + 0.3}s` : `${(index * 0.1)}s`}
            open={this.state.menuOpen}
            onClick={()=>{this.handleLinkClick();}}>{val.name}</MenuItem>)
        });

        return (
            <header>
                <nav>
                    <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()}/>

                    <Link href="/">
                        <a css={logoContainerStyle}>
                        <svg css={navLogoStyle} xmlns="http://www.w3.org/2000/svg" width="44" height="55" fill="none" viewBox="0 0 44 55">
                            <defs/>
                            <path fill="#000" d="M43 .194A.412.412 0 0042.658 0H24.949h-.05s-.048 0-.048.049l-11.74 11.64V.339c0-.048 0-.096-.05-.145-.049-.048-.049-.097-.097-.097h-.05s-.048 0-.048-.048H.342C.147 0 0 .146 0 .339v54.322c0 .145.098.242.196.29.049 0 .098.049.146.049.098 0 .147-.048.245-.097l12.425-12.32.05-.048v-.048V30.847l24.263 24.056a.375.375 0 00.245.097c.049 0 .098 0 .147-.048.147-.049.195-.146.195-.291V37.055s0-.049-.048-.049l-7.24-7.178a.375.375 0 00-.245-.097H13.55L42.902.534C43 .485 43.05.34 43 .194zM1.272 25.657l11.202 4.608v11.01L1.272 25.656zm11.35 3.977L.928 24.832l12.083-11.93c.05-.05.05-.098.098-.146L24.117 1.698 12.621 29.634zM1.564 23.232l10.86-21.534v10.718L1.566 23.232zM12.23.63L.636 23.523V.63H12.23zM.636 25.948l11.692 16.345L.636 53.885V25.948zm13.257 4.802L36.2 37.103l1.076.291v16.442L13.893 30.75zm16.388-.388l6.164 6.11-21.329-6.11h15.165zm-16.584-1.698L25.291.631h16.633L13.697 28.664z"/>
                        </svg>
                        </a>
                    </Link>

                    <Menu open={this.state.menuOpen}>
                        {menuItems}
                    </Menu>
                </nav>
            </header>
        )
      }
}