/** @jsxImportSource @emotion/react */
import React from 'react'
import Link from 'next/link'
import { mq } from "../../assets/styles/mediaqueries";
import { black, white } from '../../assets/styles/colors'

export default class MenuItem extends React.Component{
    constructor(props){
      super(props);
      this.state = {
        hover:false,
      }
    }
    
    handleHover(){
      this.setState({hover:!this.state.hover});
    }
    
    render() {
      const styles={
        container: {
          opacity: this.props.open ? 1 : 0,
          transition: `opacity .3s ease-out ${this.props.delay}`,
          [mq[2]]: {
            opacity: 1,
            color: black,
            width: 'auto',
            flexBasis: this.props.href == '/men' ? '50%' : 'initial'
          },
        },
        menuItem:{
          fontSize: '1.5rem',
          marginBottom: '20px',
          display: 'inline-block',
          cursor: 'pointer',
          color: this.state.hover ? `${white}99` : white,
          transition: `opacity .3s ease-out ${this.props.delay}, color 0.2s ease-in-out`,
          opacity: this.props.open ? 1 : 0,
  
          [mq[2]]: {
            opacity: 1,
            color: black,
            width: 'auto'
          },
        }
      }
      return(
        <div css={styles.container}>
          <Link 
            onClick={this.props.onClick}
            href={this.props.href}
          >
            <a 
            onMouseEnter={()=>{this.handleHover();}} 
            onMouseLeave={()=>{this.handleHover();}}
            css={styles.menuItem}>
                {this.props.children}  
            </a>
            
          </Link>
      </div>  
      )
    }
  }