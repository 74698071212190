
import { mq } from "../../assets/styles/mediaqueries";

export const logoContainerStyle = {
    cursor: "pointer",
    "&:hover > div": {
        opacity: 1,
    }
}

export const navLogoStyle = {
    width: 40,

    [mq[2]]: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
    },
}
