/** @jsxImportSource @emotion/react */
import React from 'react'
import { mq } from "../../assets/styles/mediaqueries";
import { black, white } from '../../assets/styles/colors'

export default class Menu extends React.Component {
    constructor(props){
      super(props);
      this.state={
        open: this.props.open? this.props.open:false,
      }
    }
      
    componentWillReceiveProps(nextProps){
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
    }
    
    render(){
      const styles={
        container: {
          position: 'absolute',
          top: 0,
          left: 0,
          height: this.state.open? '100vh': 0,
          width: '100vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center',
          background: black,
          color: white,
          transition: this.state.open ? 'height 0.3s ease' : 'height 0.3s ease .6s',
          zIndex: 2,

          [mq[2]]: {
            position: 'static',
            height: 'initial',
            backgroundColor: 'transparent',
            color: black,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between'
          },

          "@media only print": {
            display: "none"
          }
        }
      }
      return(
        <div css={styles.container}>
          {
                this.props.children
          }
        </div>
      )
    }
  }