/** @jsxImportSource @emotion/react */
import React from 'react'
import { menuButtonContainerStyle, StyledBottomLine, StyledMiddleLine, StyledTopLine } from './styles';

export default class MenuButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        open: this.props.open? this.props.open:false
      }
    }
  
    componentWillReceiveProps(nextProps) {
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
    }
    
    handleClick() {
        this.setState({open:!this.state.open});
    }
    
    render() {
      
      return(
        <div css={menuButtonContainerStyle} 
          onClick={this.props.onClick ? this.props.onClick: 
            ()=> {this.handleClick();}}>
          <StyledTopLine isOpen={this.state.open} />
          <StyledMiddleLine isOpen={this.state.open} />
          <StyledBottomLine isOpen={this.state.open} />
        </div>
      )
    }
  }