import { mq } from "../../assets/styles/mediaqueries";
import { black } from '../../assets/styles/colors'
import { marginHorizontal, marginVertical } from "../../assets/styles/globals";

export const footerStyle = {
    '&::after': {
        content: '""',
        display: 'inline-block',
        height: 1,
        width: '30vw',
        backgroundColor: black,
        marginLeft: 20,

        [mq[1]]: {
            width: 'clamp(20px, 36.11111111vw, 520px)',
        },
    },
    '&::before': {
        content: '""',
        display: 'inline-block',
        height: 1,
        width: '30vw',
        backgroundColor: black,
        marginRight: 20,

        [mq[1]]: {
            width: 'clamp(20px, 36.11111111vw, 520px)',
        },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: 0
};

export const footerContentStyle = {
    width: `calc(100% - 20px)`,
    position: "fixed",
    bottom: 50,
    left: "50%",
    transform: "translateX(-50%)",

    [mq[2]]: {
        width: `calc(100% - 200px)`,
    }
};