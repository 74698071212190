import { black, white } from '../../assets/styles/colors'
import { marginHorizontal, marginVertical } from '../../assets/styles/globals'
import { mq } from "../../assets/styles/mediaqueries";
import styled from "@emotion/styled";

export const menuButtonContainerStyle = {
    height: '32px',
    width: '32px',
    display:'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 10,

    position: 'absolute',
    top: marginVertical,
    right: marginHorizontal,

    [mq[2]]: {
    display: 'none'
    },

    "@media only print": {
        display: "none"
    }
};

const menuButtonLineStyle = {
    height: '2px',
    width: '100%',
    transition: 'all 0.2s ease',
};

export const StyledTopLine = styled.div(({ isOpen }) => ({
    ...menuButtonLineStyle,
    background: isOpen ? white : black,

    transform: isOpen ? 'translate(-1px,-4px) rotate(45deg)':'none',
    transformOrigin: 'top left',
    marginBottom: '5px',
}));
export const StyledMiddleLine = styled.div(({ isOpen }) => ({
    ...menuButtonLineStyle,
    background: isOpen ? white : black,

    opacity: isOpen ? 0: 1,
    transform: isOpen ? 'translateX(-16px)':'none',
}));
export const StyledBottomLine = styled.div(({ isOpen }) => ({
    ...menuButtonLineStyle,
    background: isOpen ? white : black,

    transform: isOpen ? 'translate(-2px,4px) rotate(-45deg)':'none',
    transformOrigin: 'top left',
    marginTop: '5px',
}));   